body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}


img {
  -webkit-user-drag: none;
}

// DROPDOWN
.Dropdown_box {
  .dropdown-toggle {
    background-color: transparent;
    color: #000000;
    border: none;
  }

  .dropdown-menu.show {
    padding: 0px;
  }

  .dropdown-item {
    // padding: 10px auto;
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    background-color: #ffffff;
    color: #000000;
    filter: drop-shadow(2px 2px 8px rgba(28, 28, 28, 0.4));
    // position: absolute;
    // bottom: -20px;
  }

  .dropdown-item:hover {
    border-radius: 5px;
    background-color: #f1f1f1;
    color: #000000;
  }

  .dropdown-toggle::after {
    display: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
  color: black;
  z-index: 1 !important;
  margin: -1px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  padding: 0px !important;

  .Logout-btn {
    background-color: #ffffff !important;
    color: #000000 !important;
    width: 100% !important;
    padding: 6px 14px !important;
    box-shadow: none !important;
    justify-content: flex-start;
    border: none !important;
    margin: 0px !important;
  }

  .Logout-btn:hover {
    background-color: #f1f1f1 !important;
  }
}

// .Logout-btn{

// }
// .Logout-btn:hover{
//   background-color: transparent !important;
// }

.topbar_main {
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 1;


  .nav_logo {
    width: 200px;
  }
}


/* dark-mode.css */
// body.dark-mode {
//   background-color: #ffffff;
//   color: #333333;

// }

// /* light-mode.css */
// body.light-mode {
//   background-color: #121212;
//   color: #ffffff;
// }

.DarkMode {
  .btn_toggle {
    background-color: transparent;
    border: none;
  }
}

// .DarkMode {
//   position: fixed;
// }

// For Home
.containers {
  // background: linear-gradient(287deg, #E6DFD3 41%, rgb(226, 144, 123) 50%);
  width: 100%;
  height: 100%;
  // padding: 2.2rem;
  // padding-top: 50px;

  .banner-div {
    margin-top: 1rem;
    height: 250px;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .banner-img {
      height: 100%;
      width: 90%;
      margin: auto;
      border-radius: 20px;
      justify-content: center;
      align-items: center;
      object-fit: cover;
      background-repeat: no-repeat;
    }
  }


  .yt-container {
    padding: 2rem 1.2rem;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;

    .img-div {
      display: flex;
      align-items: center;
      justify-content: center;

      .yt-image {
        height: 130px;
        width: 130px;
        border-radius: 100px;
        object-fit: cover;
      }

    }

    .box-content {
      // line-height: 1.2rem;

      .yt-title {
        font-size: 2.3rem;
        // margin-bottom: -.3rem;
      }

      .yt-description {
        display: flex;
        // cursor: pointer;

        p {
          color: #737373;
          padding: -1rem;
          margin-bottom: .5rem;
          color: #737373;
          // width: 700px ;
          text-overflow: ellipsis !important;
          overflow: hidden;

          .desc {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            /* Number of lines to show */
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 80%;
          }
        }
      }

      .btn {
        padding: .6rem 1rem;
        margin-top: .7rem;
        border-radius: 50px;
        color: #FFF;
        background-color: #111;
        border: none;
        cursor: pointer;
      }
    }
  }
}

// for tabs
.viewedbtn {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
  .btnaaa {
    border: 1px solid black;
    // color: white;
    
  }
}

.tabs_section {
  margin: 20px;

  .communitycards {
    .card {
      padding: 10px;
      border: none;
      width: 78%;
      margin: auto;
      margin-bottom: 10px;
      box-shadow: 2px 2px 10px 1px rgba(28, 28, 28, 0.4);

      p {
        margin-bottom: 0px !important;
      }

      .images-logo {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .content_box {
        .flex-box {
          display: flex;

          .channel_name {
            font-size: 14px;
            font-weight: 550;
          }

          .time {
            margin-left: 8px;
            font-size: 12px;
            margin-top: 2px;
          }

        }
      }

      .box-2 {
        width: 83%;
        margin: 20px auto 0px auto;
        // overflow: hidden;

        .flex-box_3 {
          // display: flex;
          // align-items: center;
          // justify-content: space-between;

          .flex_box_3 {
            display: flex;
            margin-top: 8px;

            .channel_name-2 {
              font-size: 12px;
              font-weight: bold;
            }

            .time_2 {
              font-size: 12px;
              margin-left: 8px;
            }
          }

          .overlay {
            .btn-light {
              padding: 0px !important;
              background-color: #ffffff;
              border: none;
              width: fit-content !important;

              span {
                width: fit-content !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 80%;
              }
            }

          }

          .content_3 {
            font-size: 13px;
            margin-top: 5px;
          }
        }

        .flex_box_4 {
          display: flex;
          margin-top: 12px;

          .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
            font-size: 14px;
            color: #000000;

            .css-i4bv87-MuiSvgIcon-root {
              width: 1rem;
            }
          }
        }
      }

      .content {
        font-size: 16px;
        margin-top: 10px;
      }
      p{
        font-size: 15px;
        // text-decoration: none;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 4;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
      }
      a{
        text-decoration: none;
        // color: #065fd4;
      }
      .button-hide{
        color: #6b6b6b;
        font-size: 14px;
        cursor: pointer;
        font-weight: 600;
        
      }

      .video-img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }

  .searching_tabs {
    margin-bottom: 0px;
  }

  /* width */
  .css-jpln7h-MuiTabs-scroller::-webkit-scrollbar {
    width: 10px;
    display: none !important;
  }

  .css-13xfq8m-MuiTabPanel-root {
    min-height: 350px;
  }

  .css-jpln7h-MuiTabs-scroller {
    overflow-x: auto !important;
  }

  // .css-heg063-MuiTabs-flexContainer::-webkit-scrollbar {
  //   width: 10px;
  //   display: none !important;
  // }
  // .css-heg063-MuiTabs-flexContainer{
  //   overflow-x: auto !important;
  // }

  .tabs_text {
    color: #ffffff;
  }

  // Searchbox

  .searchbox {
    min-width: 200px;
    display: flex;
    align-items: start;

    .search_symbol {
      background-color: transparent;
      color: rgba(0, 0, 0, 0.6);
      border: none;
      // padding-bottom: 0px;
      box-shadow: none;
      --bs-btn-padding-y: 14px;
    }

    .input-group {
      width: 0%;
      transition: width 1s ease;
      // border-bottom: 1px solid #000000;

    }

    .form-control {
      background-color: transparent;
      box-shadow: none;
      border: none;
      border-bottom: 1px solid #000000;
      border-radius: 0 !important;
      display: none; // Initially hiding the input
      margin-bottom: 12px;
      min-width: 40px !important;
    }

    &.formVisible {
      .input-group {
        width: 100%;
      }

      .form-control {
        display: block;
      }
    }
  }

  // About_tag

  .About_tag {
    .about_part {
      padding-bottom: 20px;

      p {
        font-size: 14px;
        margin-bottom: 0px;
      }
    }

    .flex_box {
      display: flex;
      align-items: center;
      padding: 10px 0px;

      // Icons color
      .fb {
        color: #065fd4;
      }

      .linkedin {
        color: #065fd4;

      }

      .instagram {

        color: white;
        border-radius: 10px;
        background: linear-gradient(#f9ce34, #ee2a7b, #6228d7);
      }

      .dtl {
        padding-left: 10px;

        h5 {
          font-size: 14px;
          margin-bottom: 0px;
          padding-bottom: 5px;
        }

        a {
          font-size: 12px;
          color: #065fd4;
        }
      }

      .css-i4bv87-MuiSvgIcon-root {
        font-size: 35px;
      }
    }

    .channel_section {
      h5 {
        padding-top: 20px;
        margin-bottom: 10px;
      }

      .mail {
        display: flex;
        align-items: center;
        padding-bottom: 10px;

        .css-i4bv87-MuiSvgIcon-root {
          font-size: 32px;
        }

        p {
          margin-bottom: 0px;
          padding-bottom: 0px;
          padding-left: 12px;
          font-size: 14px;
        }
      }
    }

    .Btn_section {
      display: flex;

      .btn {
        background-color: #e1dede;
        color: #000000;
        border: none;
        box-shadow: none;
        border-radius: 10px;
        font-size: 12px;
      }

      .btn:hover {
        background-color: #c7c5c5;
      }
    }
  }



}


// Video Section
.Videocards {
  .cards {
    .AllCards {
      .loader-img {
        width: 150px;
        height: 150px;
      }

      .card_div {
        overflow: hidden;
        border: none;
        cursor: pointer;
        margin-bottom: 30px;
        background-color: transparent;
        min-height: 250px;

        .card_details {
          .Card_img {
            // height: 350px;
            width: 100%;
            height: 100%;
            position: relative;

            video {
              height: 100%;
              width: 100%;
            }

            img {
              width: 100%;
              height: 100%;
            }

            .download-btn {
              background-color: transparent;
              color: rgb(0, 0, 0);
              border: none;
              position: absolute;
              top: 0px;
              left: 0px;
              display: none;

              .css-i4bv87-MuiSvgIcon-root {
                border-radius: 100%;
                font-size: 30px;
                background-color: white;
                color: rgb(0, 0, 0);
              }
            }


          }

          .Card_img:hover {
            opacity: 0.8;
          }

          .Card_img:hover .download-btn {
            // opacity: 0.9;
            z-index: 1;
            display: block;

          }

          .card_intro {
            display: flex;
            justify-content: space-between;
            align-self: start;
            padding: 10px 0px;


            h5 {
              font-family: "Roboto", "Arial", sans-serif;
              font-weight: 550;
              font-size: 14px;
              color: #0F0F0F;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              /* Number of lines to show */
              -webkit-box-orient: vertical;
              overflow: hidden;
              max-width: 98%;
            }

            span {
              color: #606060;
              font-size: 12px;
            }
          }

          .card_intro:hover .dropdown-toggle {
            display: block;
          }
        }
      }
    }
  }

}


// Carousel Section
.Carousel {

  .react-multiple-carousel__arrow {
    background-color: #ffffff;
    color: #000000;
  }

  .react-multiple-carousel__arrow:hover {
    background-color: #acacac;
    color: #000000;
  }

  // right arrow 

  .react-multiple-carousel__arrow--right::before {
    color: #000000;
  }


  // // left arrow
  .react-multiple-carousel__arrow--left::before {
    color: #000000;
  }



  .card_div {
    overflow: hidden;
    border: none;
    cursor: pointer;
    margin-bottom: 30px;
    background-color: transparent;

    .card_details {
      .Card_img {
        width: 100%;
        height: 100%;
        position: relative;

        video {
          height: 100%;
          width: 100%;
          border-radius: 12px;
          padding-right: 5px;
        }

        img {
          border-radius: 12px;
          padding-right: 5px;
          width: 100%;
        }

        .download-btn {
          background-color: #000000;
          color: #ffffff;
          border: none;
          position: absolute;
          bottom: 8px;
          right: 5px;
          font-size: 12px;
        }

        .play_button {
          background-color: rgba(34, 34, 34, 0.932);
          border-radius: 12px;
          color: #ffffff;
          border: none;
          position: absolute;
          // top: 0%;
          right: 2%;
          bottom: 5px;
          display: none;
          padding: 10px;
          font-size: 14px;
          font-weight: 600px;
          width: 100%;
          height: 100%;
          text-decoration: none;
        }


      }


      .Card_img:hover .play_button {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .card_intro {
        display: flex;
        justify-content: space-between;
        padding: 10px 0px;

        h5 {
          font-family: "Roboto", "Arial", sans-serif;
          font-weight: 550;
          font-size: 14px;
          color: #0F0F0F;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* Number of lines to show */
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-width: 98%;
        }

        span {
          color: #606060;
          font-size: 12px;
        }
      }
    }
  }

}


// Playlist Section
.Playlistcard {
  .cards {
    .AllCards {
      .loader-img {
        width: 150px;
        height: 150px;
      }

      .card_div {
        overflow: hidden;
        border: none;
        cursor: pointer;
        margin-bottom: 30px;
        background-color: transparent;

        .card_details {
          .Card_img {
            // height: 350px;
            width: 100%;
            height: 100%;
            position: relative;
            border-top: 2px solid #6b6b6b3b;
            padding-top: 5px;
            border-radius: 5px;

            video {
              height: 100%;
              width: 100%;
              border-radius: 5px;
            }

            .download-btn {
              background-color: #000000;
              color: #ffffff;
              border: none;
              position: absolute;
              bottom: 8px;
              right: 4px;
              // display: none; 
              font-size: 12px;
            }

            .play_button {
              background-color: rgba(34, 34, 34, 0.932);
              border-radius: 5px;
              color: #ffffff;
              border: none;
              position: absolute;
              top: 0%;
              left: 0%;
              display: none;
              padding: 10px;
              font-size: 14px;
              font-weight: 600px;
              width: 100%;
              height: 100%;
              text-decoration: none;
            }


          }


          .Card_img:hover .play_button {
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .card_intro {
            display: flex;
            justify-content: space-between;
            padding: 10px 0px;

            h5 {
              font-family: "Roboto", "Arial", sans-serif;
              font-weight: 550;
              font-size: 14px;
              color: #0F0F0F;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              /* Number of lines to show */
              -webkit-box-orient: vertical;
              overflow: hidden;
              max-width: 98%;
            }

            span {
              color: #606060;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .show_more{
    border: none;
    background-color:black;
    color: white;
    padding: 10px 30px;
    border-radius: 8px;
    margin-left: 42%;
  }
}

// PLAY 
.Play {
  padding: 20px 0px;

  #videosection {
    video {
      border-radius: 5px;
      width: 100%;
      // height: 100%;
    }

    .content {
      margin: 0px;
      font-weight: 600;
      font-size: 20px;
      padding: 10px 0px;
    }

    .channel_details {
      display: flex;
      align-items: center;

      .logo_img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }

      .name {
        padding-left: 5px;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  #VideosCollection {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;

    .Upper_portion {
      .flex_box {
        display: flex;
        justify-content: space-between;

        .title {
          margin: 0px;
          font-weight: 600;
          font-size: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .channelName {
          margin: 0px;
          font-weight: 500;
          font-size: 12px;
          color: rgb(94, 93, 93);
        }


      }
    }

    .collection {
      padding-top: 10px;
      height: 80vh;
      overflow-y: auto;
      overflow-x: hidden;

      .flex_box_2 {
        display: flex;
        align-items: center;

        video {
          width: 80%;
          // height: 100%;
          border-radius: 5px;

        }

        .flex_box_3 {
          display: flex;
          flex-direction: column;
        }

        .title {
          // padding-left: 5px;
          margin: 0px;
          font-size: 12px;
          font-weight: 600;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* Number of lines to show */
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}

// Short Videos
.Shortcards {
  .cards {
    .AllCards {
      .loader-img {
        width: 150px;
        height: 150px;
      }

      .card_div {
        overflow: hidden;
        border: none;
        cursor: pointer;
        margin-bottom: 30px;

        .card_details {


          .shortCard_img {
            // height: 350px;
            width: 100%;
            height: 100%;
            position: relative;

            img {
              min-height: 100%;
              height: 100%;
              width: 100%;
            }

            .download-btn {
              background-color: transparent;
              color: rgb(0, 0, 0);
              border: none;
              position: absolute;
              top: 0px;
              left: 0px;
              display: none;

              .css-i4bv87-MuiSvgIcon-root {
                border-radius: 100%;
                font-size: 35px;
                background-color: white;
                color: rgb(0, 0, 0);
              }
            }


          }

          .shortCard_img:hover {
            opacity: 0.9;
          }

          .shortCard_img:hover .download-btn {
            z-index: 1;
            display: block;
          }


          .card_intro {
            display: flex;
            justify-content: space-between;
            padding: 10px 0px;
            background-color: #dfdbdb25;

            h5 {
              font-weight: 550;
              font-size: 14px;
              color: #0F0F0F;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              /* Number of lines to show */
              -webkit-box-orient: vertical;
              overflow: hidden;
              max-width: 98%;
            }

            span {
              color: #606060;
              font-size: 14px;
            }


          }
        }
      }
    }
  }
}

// for mainpage
.mainpage {
  background: #dfdbdb25;
  width: 100%;
  height: 100%;

  .main_content {

    // height: 70vh;

    .main_heading {
      // height: 70vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      background: linear-gradient(48deg, #3b3b3b 20%, #c04928 78%, #4c4966 100%);
      padding: 30px;
      h5 {
        color: #ffffff;
        font-size: 35px;
        text-align: center;

      }

      span {
        font-size: 22px;
        color: #d4d0d0;
      }

      .Input-section {
        border: 1px solid transparent;
        border-radius: 20px;
        background-color: #3b3b3b;

        display: flex;
        justify-content: space-between;
        padding: 5px 5px;
        align-items: baseline;

        .input_field {
          background: none;
          outline: none;
          border: none;
          color: #ffffff;
          padding-left: 5px;
          width: 80%;
        }

        .inp_btn {
          background: none;
          border: none;
          color: #c4c1c1;
        }

        .inp_btn:hover {
          color: #ffffff;
        }
      }

      .chnl_card {
        // cursor: pointer;

        .cardd {
          width: fit-content;
          padding: 0px 20px;
          // height: 8rem;
          border: 1px solid #83090988;
          background: linear-gradient(to bottom right, #ececec42, rgba(90, 42, 42, 0.377));
          border-radius: 10px;
          box-shadow: 0 8px 32px 0 #351818b2;

          .yt-container {
            padding: 1rem 2rem;
            display: flex;
            flex-direction: row;
            column-gap: 1rem;
            align-items: center;

            .img-div {
              display: flex;
              align-items: center;
              justify-content: center;

              .yt-image {
                height: 100px;
                width: 100px;
                border-radius: 100px;
                object-fit: cover;
              }
            }

            .box-content {

              .yt-title {
                font-size: 1.5rem;

                color: #FFF;
                // margin-bottom: -.3rem;
              }

              p {
                font-size: 0.7rem;
                color: #c5c4c4;

              }
            }
          }
        }
      }

    }

    .info_section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .Info_card {
        width: 80%;
        padding: 30px;
        margin: 30px;
        border-radius: 20px;
        box-shadow: 2px 2px 10px 1px rgba(28, 28, 28, 0.4);

        .container_row {
          padding: 20px;

          .yt-image {
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;

          }

          .comp_card {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }

          .card_content {
            // padding: 20px;
            text-align: center;

            h5 {
              color: #252e69;
              display: inline-block;
              font-size: 1.5rem;
              font-style: normal;
              font-weight: 900;
              line-height: 1.1em;
              margin-bottom: 15px;
              min-height: 60px;
              // text-align: center;
            }

            p {
              color: #252e69;
              font-size: 18px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
              // text-align: center;
            }
          }
        }
      }

      .text_card {
        width: 80%;
        padding: 30px;
        margin: 30px;
        border-radius: 20px;
        box-shadow: 2px 2px 10px 1px rgba(28, 28, 28, 0.4);

        .text_container {
          padding: 30px;

          h5 {
            color: #252e69;
            font-size: 35px;
            font-style: normal;
            // line-height: 2em;
            margin-bottom: 20px;
            text-align: center;
          }

          p {
            color: #252e69;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            text-align: left;
            margin-bottom: 0px;
            line-height: 26px;
          }
        }
      }
    }

    .footer_section {
      .footer_content {
        width: 100%;
        // height: 150px;
        background: linear-gradient(48deg, #3b3b3b 20%, #c04928 78%, #4c4966 100%);

        .footer_heading {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 30px;

          h5 {
            font-size: 27px;
            color: #ffffff;
          }

          p {
            color: #ffffff;
            opacity: 0.7;
            font-weight: 100;
          }
        }
      }
    }
  }
}

// #accordians
.accordians {
  .acrd_main {
    margin-bottom: 20px;
  }

  .acordn_heading {
    color: rgb(37, 46, 105);
    font-weight: 600 !important;
    font-size: 22px !important;
    padding: 10px
  }

  .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    box-shadow: 0 0 8px 1px #c7c6c6;
  }

  .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    // box-shadow: 2px 2px 2px 1px rgb(199, 198, 198);


  }

  .Content {
    font-size: 16px !important;
    padding-top: 0px;
  }

  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    margin: 10px 0px;
  }
}

// Login page

.Login_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #dfdbdb25;

  .card {
    box-shadow: 2px 2px 10px 1px rgba(28, 28, 28, 0.4);
    border: none;
    max-width: 400px;
    // margin: auto;
    padding: 30px 20px;

    .logo {
      .login_image {
        height: 70px;
        width: 70px;
        display: flex;
        justify-content: center;
        margin: auto;
      }
    }

    .Login_content {
      padding: 20px 0px;
      color: rgba(47, 43, 61, 0.78);

      h5 {
        font-size: 22px;
      }

      p {
        margin-bottom: 0px;
        font-size: 15px;
      }
    }

    .Input_field {
      position: relative;
      margin-bottom: 10px;

      label {
        padding-bottom: 5px;
      }

      .input {
        padding: 4px 4px;
        width: 100%;
        // box-shadow: 2px 6px 6px 0px rgba(28, 28, 28, 0.4);
        border-radius: 4px;
        border: 0.5px solid rgba(17, 17, 17, 0.2);
      }

      .input:focus {
        // border: 2px solid rgb(0, 0, 0);
        border: none !important;
        box-shadow: 2px 6px 6px 3px rgba(28, 28, 28, 0.4);
        margin-bottom: 5px;
        border-radius: 2px;
      }

      .eye_icon {
        position: absolute;
        right: 5px;
        top: 4px;
      }
    }

    .Login_button {
      background-color: rgb(240, 64, 64);
      color: #ffffff;
      width: 100%;
      border-radius: 5px;
      border: none;
      box-shadow: none;
      margin: 10px 0px;
      box-shadow: 2px 4px 10px 1px rgba(28, 28, 28, 0.4);
      padding: 8px 0px;
    }

    .Login_button:hover {
      background-color: rgb(241, 33, 33);
    }

    a {
      text-decoration: none;
      color: rgb(115, 103, 240);
      font-weight: 500;
      font-size: 14px;
    }

    .create_acc {
      display: flex;
      justify-content: center;

      span {
        font-size: 14px;

        color: rgba(47, 43, 61, 0.78);
      }
    }
  }
}

// Sign up

.signup_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #dfdbdb25;

  .card {
    box-shadow: 2px 2px 10px 1px rgba(28, 28, 28, 0.4);
    border: none;
    min-width: 400px !important;
    max-width: 400px;
    // margin: auto;
    padding: 30px 20px;

    .logo {
      .signup_image {
        height: 70px;
        width: 70px;
        display: flex;
        justify-content: center;
        margin: auto;
      }
    }

    .signup_content {
      padding: 20px 0px;
      color: rgba(47, 43, 61, 0.78);

      h5 {
        font-size: 22px;
      }

      p {
        margin-bottom: 0px;
        font-size: 15px;
      }
    }

    .Input_field {
      position: relative;
      margin-bottom: 10px;

      label {
        padding-bottom: 5px;
      }

      .input {
        padding: 4px 4px;
        width: 100%;
        // box-shadow: 2px 6px 6px 0px rgba(28, 28, 28, 0.4);
        border-radius: 4px;
        border: 0.5px solid rgba(17, 17, 17, 0.2);
      }

      .input:focus {
        // border: 2px solid rgb(0, 0, 0);
        border: none !important;
        box-shadow: 2px 6px 6px 3px rgba(28, 28, 28, 0.4);
        margin-bottom: 5px;
        border-radius: 2px;
      }

      .eye_icon {
        position: absolute;
        right: 5px;
        top: 4px;
      }
    }

    // .form{
    //   .Form_group{
    //     margin-bottom: 10px;
    //     color: rgba(47, 43, 61, 0.78);
    //     position: relative;
    //       .form-label{

    //           margin-bottom: 5px;
    //           font-size: 13px;
    //       }
    //       .form-control{
    //         font-size: 14px;
    //         color: rgba(47, 43, 61, 0.78);
    //         box-shadow: none;
    //       }
    //       .form-control:focus{
    //         border: 2px solid rgb(0, 0, 0);
    //         box-shadow: 2px 2px 6px 0px rgba(28, 28, 28, 0.4);
    //       }
    //       .eye_icon{
    //         position: absolute;
    //         right: 10px;
    //         top: 30px;
    //         cursor: pointer;
    //         .Visible {
    //           color: rgba(0, 0, 0, 0.78);
    //         }
    //       }
    //   }
    // }
    .policy {
      .form-check {
        margin-right: 5px;

        .form-check-input {
          box-shadow: none;
        }
      }
    }

    .signup_button {
      background-color: rgb(240, 64, 64);
      color: #ffffff;
      width: 100%;
      border-radius: 5px;
      border: none;
      box-shadow: none;
      margin: 10px 0px;
      box-shadow: 2px 4px 10px 1px rgba(28, 28, 28, 0.4);
      padding: 8px 0px;
    }

    .signup_button:hover {
      background-color: rgb(241, 33, 33);
    }

    a {
      text-decoration: none;
      color: rgb(115, 103, 240);
      font-weight: 500;
      font-size: 14px;
    }

    .create_acc {
      display: flex;
      justify-content: center;

      span {
        font-size: 14px;

        color: rgba(47, 43, 61, 0.78);
      }
    }
  }
}

// Forget Password

.Password_Page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #dfdbdb25;

  .card {
    box-shadow: 2px 2px 10px 1px rgba(28, 28, 28, 0.4);
    border: none;
    max-width: 400px;
    // margin: auto;
    padding: 30px 20px;

    .logo {
      .logo_image {
        height: 70px;
        width: 70px;
        display: flex;
        justify-content: center;
        margin: auto;
      }
    }

    .Logo_content {
      padding: 20px 0px;
      color: rgba(47, 43, 61, 0.78);

      h5 {
        font-size: 22px;
      }

      p {
        margin-bottom: 0px;
        font-size: 15px;
      }
    }

    .form-group {
      margin-bottom: 10px;

      label {
        padding-bottom: 5px;
      }

      .Error_msg {
        color: #ff0000;
      }

      .input {
        padding: 4px 4px;
        width: 100%;
        // box-shadow: 2px 6px 6px 0px rgba(28, 28, 28, 0.4);
        border-radius: 4px;
        border: 0.5px solid rgba(17, 17, 17, 0.2);
      }

      .input:focus {
        // border: 2px solid rgb(0, 0, 0);
        border: none !important;
        box-shadow: 2px 6px 6px 3px rgba(28, 28, 28, 0.4);
        margin-bottom: 5px;
        border-radius: 2px;
      }

      .eye_icon {
        position: absolute;
        right: 5px;
        top: 4px;
      }
    }

    // .form{
    //   .Form_group{
    //     margin-bottom: 10px;
    //     color: rgba(47, 43, 61, 0.78);
    //     position: relative;
    //       .form-label{

    //           margin-bottom: 5px;
    //           font-size: 13px;
    //       }
    //       .form-control{
    //         font-size: 14px;
    //         color: rgba(47, 43, 61, 0.78);
    //         box-shadow: none;
    //         background-color: #fff;
    //       }
    //       .form-control:focus{
    //         border: 2px solid rgb(0, 0, 0);
    //         box-shadow: 2px 2px 6px 0px rgba(28, 28, 28, 0.4);
    //       }
    //       .eye_icon{
    //         position: absolute;
    //         right: 10px;
    //         top: 30px;
    //         cursor: pointer;
    //         .Visible {
    //           color: rgba(0, 0, 0, 0.78);
    //         }
    //       }
    //   }
    // }
    .Login_button {
      background-color: rgb(240, 64, 64);
      color: #ffffff;
      width: 100%;
      border-radius: 5px;
      border: none;
      box-shadow: none;
      margin: 10px 0px;
      box-shadow: 2px 4px 10px 1px rgba(28, 28, 28, 0.4);
      padding: 8px 0px;
    }

    .Login_button:hover {
      background-color: rgb(241, 33, 33);
    }

    a {
      text-decoration: none;
      color: rgb(115, 103, 240);
      font-weight: 500;
      font-size: 14px;
    }

    .create_acc {
      display: flex;
      justify-content: center;

      span {
        font-size: 14px;

        color: rgba(47, 43, 61, 0.78);
      }
    }
  }
}