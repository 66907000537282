@media screen and (max-width: 1200px) {
}

@media screen and (max-width: 992px) {
  .communitycards {
    .card {
      p {
        margin-bottom: 0px !important;
      }

      .content_box {
        .flex-box {
          flex-direction: column !important;

          .time {
            margin-top: 8px !important;
            margin-left: 0px !important;
            margin-bottom: 8px !important;
          }
        }
      }

      .box-2 {
        .flex-box_3 {
          flex-direction: column !important;

          .content_3 {
            display: none !important;
          }
        }
      }

      .video-img {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .Play {
    padding: 20px 0px;

    #VideosCollection {
      .collection {
        padding-top: 10px;
        height: 100%;
      }
    }
  }
  //   communitycards
  .communitycards {
    .card {
      width: 100% !important;

      p {
        margin-bottom: 0px !important;
      }

      .content_box {
        .flex-box {
          flex-direction: column !important;

          .time {
            margin-top: 8px !important;
            margin-left: 0px !important;
          }
        }
      }

      .box-2 {
        .flex-box_3 {
          flex-direction: column !important;

          .content_3 {
            display: none !important;
          }
        }
      }

      .video-img {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  // for mainpage

  .mainpage {
    .main_content {
      .main_heading {
        h5 {
          font-size: 30px !important;
        }

        span {
          font-size: 18px !important;
        }
      }

      .info_section {
        .Info_card {
          .container_row {
            .card_content {
              h5 {
                margin-bottom: 0px !important;
                min-height: 40px !important;
              }

              p {
                font-size: 16px !important;
              }
            }
          }
        }

        .text_card {
          .text_container {
            padding: 30px 0px 30px 0px !important;

            h5 {
              font-size: 30px !important;
            }

            p {
              font-size: 20px;
            }
          }
        }
      }

      .footer_section {
        .footer_content {
          .footer_heading {
            padding: 20px 0px !important;

            h5 {
              font-size: 24px !important;
            }

            p {
              margin-bottom: 0px !important;
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  // for home screen
  .containers {
    .banner-div {
      height: 130px;
    }

    .yt-container {
      .img-div {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .css-13xfq8m-MuiTabPanel-root {
    padding: 24px 0px !important;
  }
  .Playlistcard {
    .show_more {
      margin-left: 35% !important;
    }
  }
  .communitycards {
    .card {
      width: 100% !important;

      p {
        margin-bottom: 0px !important;
      }

      .images-logo {
        width: 40px !important;
        height: 40px !important;
      }

      .content_box {
        .flex-box {
          flex-direction: column !important;

          .time {
            margin-top: 8px !important;
            margin-left: 0px !important;
          }
        }
      }

      .box-2 {
        .flex-box_3 {
          flex-direction: column !important;
          .flex_box_3 {
            display: none !important;
          }

          .overlay {
            p {
              font-size: 14px !important;
            }
          }

          .content_3 {
            display: none !important;
          }
        }
      }

      .video-img {
        width: 100% !important;
        // height: 192px !important;
      }
    }
  }
  // Searchbox

  .searchbox {
    display: none;
  }
  // for mainpage

  .mainpage {
    .main_content {
      .main_heading {
        h5 {
          font-size: 30px !important;
        }

        span {
          font-size: 18px !important;
        }

        .chnl_card {
          .cardd {
            height: fit-content !important;

            .yt-container {
              .img-div {
                .yt-image {
                  height: 80px !important;
                  width: 80px !important;
                }
              }

              .box-content {
                .yt-title {
                  font-size: 20px !important;
                }
              }
            }
          }
        }
      }

      .info_section {
        .Info_card {
          .container_row {
            .yt-image {
              height: 100px !important;
            }

            .card_content {
              h5 {
                margin-bottom: 10px !important;
                min-height: 40px !important;
                font-size: 20px !important;
              }

              p {
                font-size: 14px !important;
              }
            }
          }
        }

        .text_card {
          .text_container {
            padding: 30px 0px 30px 0px !important;

            h5 {
              font-size: 25px !important;
            }

            p {
              font-size: 15px;
            }
          }
        }
      }

      .footer_section {
        .footer_content {
          .footer_heading {
            h5 {
              font-size: 16px !important;
              text-align: center;
            }

            p {
              margin-bottom: 0px !important;
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }

  .yt-title {
    font-size: 1.5rem !important;
    // margin-bottom: -.3rem;
  }

  // Sign up
  .signup_page {
    .card {
      min-width: fit-content !important;
    }
  }

  // ShortCards
  .cards {
    .card_div {
      width: 50%;
      margin: auto;
    }
  }
}

@media screen and (max-width: 400px) {
  .Playlistcard {
    .show_more {
      margin-left: 24% !important;
    }
  }
  // for mainpage

  .mainpage {
    .main_content {
      .main_heading {
        h5 {
          font-size: 25px !important;
        }

        span {
          font-size: 15px !important;
        }

        .chnl_card {
          .cardd {
            .yt-container {
              padding: 1rem 0.5rem !important;

              .img-div {
                .yt-image {
                  height: 60px !important;
                  width: 60px !important;
                }
              }

              .box-content {
                .yt-title {
                  font-size: 18px !important;
                }
              }
            }
          }
        }
      }

      .info_section {
        .Info_card {
          .container_row {
            .card_content {
              h5 {
                margin-bottom: 10px !important;
                min-height: 40px !important;
              }

              p {
                font-size: 14px !important;
              }
            }
          }
        }

        .text_card {
          padding: 20px 10px !important;

          .text_container {
            padding: 30px 0px 30px 0px !important;

            h5 {
              font-size: 25px !important;
            }

            p {
              font-size: 15px !important;
            }
          }
        }
      }
    }
  }

  // ShortCards
  .cards {
    .card_div {
      width: 70%;
      margin: auto;
    }
  }
}
